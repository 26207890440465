import configI18n from 'config/i18n'

/**
 * Helper para diminuir código duplicado ao gerar links dos aplicativos
 * mobile seguindo as normas de languageCode.
 */
export default {
  /**
   * Retorna apenas o locale do código da língua da aplicação em upperCase.
   * @param {string} langCode código de língua da aplicação.
   * @return {string} locale no formato ISO 3166-2
   */
  getLocaleFromTag(langCode) {
    let locale = configI18n.defaultLocale

    if (langCode.indexOf('-') !== -1) {
      locale = langCode.split('-')[1]
    } else if (langCode === 'en') {
      locale = 'us'
    } else if (langCode === 'es') {
      locale = 'es'
    }
    return locale.toUpperCase()
  },
  /**
   * Retorna apenas a língua do código da língua da aplicação em lowerCase.
   * @param {string} langCode código de língua da aplicação.
   * @return {string} language no formato ISO639-2
   */
  getLanguageFromTag(langCode) {
    let language = langCode
    if (langCode.indexOf('-') !== -1) {
      language = langCode.split('-')[0]
    }

    return language.toLowerCase()
  },
  /**
   * Transforma uma tag de língua do padrão da aplicação para o padrão RFC5646.
   * https://tools.ietf.org/html/rfc5646
   * @param {string} langCode código da língua da aplicação.
   * @return {string} retorna código da língua (RFC5646).
   */
  normalizeLanguageTag(langCode) {
    let normalizedTag = langCode
    let language = null
    let locale = null

    if (langCode.indexOf('-') !== -1) {
      [language, locale] = langCode.split('-')
      normalizedTag = language.toLowerCase() + '-' + locale.toUpperCase()
    } else if (langCode === 'en') {
      normalizedTag = 'en-US'
    } else if (langCode === 'es') {
      normalizedTag = 'es-ES'
    }

    return normalizedTag
  },
  /**
   * Retorna o slug usado na montagem de link da página do App na Apple Store.
   * @param {String} langCode código da língua atual.
   * @return {String} appSlug.
   */
  getAppleAppSlugs(langCode) {
    const defaultSlug = 'rentcars-com-car-rental-made-easy'
    const appSlugList = {
      'rentcars.com-aluguel-carro': ['pt-br', 'pt-pt'],
      'rentcars-com-alquiler-de-coches': ['es', 'es-mx', 'es-cl']
    }

    let appSlug = defaultSlug

    for (const slug in appSlugList) {
      if (appSlugList[slug].includes(langCode)) {
        appSlug = slug
        break
      }
    }

    return appSlug
  }
}
