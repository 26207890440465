import AppStore from 'mixins/app-store'
import i18nConfig from 'config/i18n'
import AmountCompanyAndCountry from '@/mixins/amount-company-country'

export default {
  name: 'view-app-page',

  mixins: [AppStore, AmountCompanyAndCountry],

  fetch({ store }) {
    store.dispatch('header/SET_CONFIG', {
      logo: true,
      menu: true,
      recentSearch: true,
      showFlag: true
    })

    store.dispatch('footer/SET_CONFIG', {
      show: true
    })
  },

  data() {
    return {
      faq: this.$store.getters['faq/getFaq']
    }
  },

  head() {
    return {
      title: this.$t('seo.app.title'),
      link: this.getPageLinks(),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.$t('seo.app.title')
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('seo.app.description')
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('seo.app.title')
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('seo.app.description')
        }
      ]
    }
  },

  methods: {
    getPageLinks() {
      const links = i18nConfig.availableLocales.map((key) => {
        return {
          rel: 'alternate',
          hid: 'lang-' + key,
          href: this.getPageAlternateForLocale(key),
          hreflang: key
        }
      })

      links.push({
        rel: 'alternate',
        hid: 'lang-xlang',
        href: this.$routeResolver('routes.app-page', 'en', process.env.BASE_URL),
        hreflang: 'x-default'
      })

      links.push({
        hid: 'canonical',
        rel: 'canonical',
        href: process.env.CANONICAL_URL + this.$route.path
      })

      return links
    },

    getPageAlternateForLocale(key) {
      return this.$routeResolver('routes.app-page', key, process.env.BASE_URL)
    }
  }
}
