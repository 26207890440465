import configI18n from 'config/i18n'
import langHelper from 'helpers/langHelper'
import abTestsMixin from './ab-tests-mixin'

export default {
  mixins: [abTestsMixin],

  computed: {
    /**
     * @desc gets tha Apple Store link by locale
     * @returns {String}
     */
    urlAppleStore () {
      const locale = langHelper.getLocaleFromTag(this.$i18n.locale).toLowerCase()
      const appSlug = langHelper.getAppleAppSlugs(this.$i18n.locale)
      return `https://itunes.apple.com/${locale}/app/${appSlug}/id918167519?mt=8`
    },

    /**
     * @desc gets tha Google Play Store link by locale
     * @returns {String}
     */
    urlPlayStore () {
      return `https://play.google.com/store/apps/details?id=com.rentcars.rentcarscom&hl=${langHelper.normalizeLanguageTag(this.$i18n.locale)}`
    },

    /**
     * @desc gets the Apple Store image by locale
     * @returns {String}
     */
    imageAppleStore () {
      return `/images/apple-store/${this.localeAppleStore}/black.svg`
    },

    /**
     * @desc gets the Google Play Store image by locale
     * @returns {String}
     */
    imagePlayStore () {
      return 'https://play.google.com/intl/en_us/badges/images/generic/' + this.localePlayStore.split('-')[0] + '_badge_web_generic.png'
    },

    imagePlayStoreV2 () {
      return 'https://play.google.com/intl/en_us/badges/images/generic/' + this.localePlayStore + '_badge_web_generic.png'
    },

    /**
     * Retorna as tags usadas para gerar os links que capturam imagens da AppleStore.
     * @returns {String} langCode no padrão aceito pelo link da AppleStore.
     */
    localeAppleStore () {
      let appleLangTag = configI18n.defaultLocales[this.$i18n.locale].code
        .toLowerCase()
        .replace('_', '-')

      switch (appleLangTag) {
        case 'es-cl':
        case 'es-co':
          appleLangTag = 'es-es' /** o locale es-cl não retorna uma imagem no link usado. */
      }
      return appleLangTag
    },

    /**
     * Retorna as tags usadas para gerar os links que capturam imagens da PlayStore.
     * @param {String} langCode tag do idioma que será tratado.
     * @returns {String} langCode no padrão aceito pelo link da PlayStore.
     */
    localePlayStore () {
      switch (this.$i18n.locale) {
        case 'es-mx':
        case 'es-cl':
          return 'es-419'
        case 'pt-pt':
          return 'pt'
        case 'de-de':
          return 'de'
        case 'it-it':
          return 'it'
        case 'en-gb':
          return 'en'
        case 'fr-fr':
          return 'fr'
        default:
          return this.$i18n.locale
      }
    },

    /**
     * gets the AppStore slug by device
     * @returns {String}
     */
    slugAppStore () {
      if (this.$store.getters.isIOS) {
        return 'apple-store'
      } else if (this.$store.getters.isAndroid) {
        return 'play-store'
      }
    },

    /**
     * gets the AppStore slug by device
     * @returns {String}
     */
    textButtonAppStore () {
      if (this.$store.getters.isIOS) {
        return this.$t('downloadapp.apprentcars_iphone')
      } else if (this.$store.getters.isAndroid) {
        return this.$t('downloadapp.apprentcars_android')
      }
    },

    /**
     * gets the AppStore link by device
     * @returns {String}
     */
    urlAppStore () {
      if (this.$store.getters.isIOS) {
        return this.urlAppleStore
      } else if (this.$store.getters.isAndroid) {
        return this.urlPlayStore
      }
    },

    /**
     * gets the AppStore image by device
     * @returns {String}
     */
    imageAppStore () {
      const variantIdHome = this.$store.getters['abTests/getHomeAbTest']
      if (this.$store.getters.isIOS) {
        return this.imageAppleStore
      } else if (this.$store.getters.isAndroid && variantIdHome === 1) {
        return this.imagePlayStoreV2
      } else if (this.$store.getters.isAndroid) {
        return this.imagePlayStore
      }
    }
  }
}
