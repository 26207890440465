export default {
  methods: {
    getRentalCompanyAmount () {
      return {
        'COUNT_RENTAL_STORES': process.env.COUNT_RENTAL_STORES
      }
    },

    getCountryAmount () {
      return {
        'COUNT_COUNTRIES': process.env.COUNT_COUNTRIES
      }
    },

    getCountryAndCompanyAmount () {
      return Object.assign({}, this.getCountryAmount(), this.getRentalCompanyAmount())
    },

    replaceVariables (phrase) {
      const countCountries = '{{COUNT_COUNTRIES}}'
      const countRenalStores = '{{COUNT_RENTAL_STORES}}'
      let responsePhrase = phrase.includes(countCountries) ? phrase.replace(/{{COUNT_COUNTRIES}}/g, process.env.COUNT_COUNTRIES) : phrase
      responsePhrase = phrase.includes(countRenalStores) ? responsePhrase.replace(/{{COUNT_RENTAL_STORES}}/g, process.env.COUNT_RENTAL_STORES) : responsePhrase

      return responsePhrase
    }
  }
}
